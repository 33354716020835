import React from 'react';
import { isMobile } from 'react-device-detect';

import Layout from 'components/layouts/main/Layout';
import Dropbox from 'components/shared/dropbox/DropboxEmbed';

export default class InvitePage extends React.Component<any> {
  render() {
    return (
      <Layout location={this.props.path}>
        <div
          style={{
            height: '200vh',
            padding: !isMobile ? '10vw 6.5% 3vw 14%' : '15vh 5vw 5vh',
            backgroundImage:
              'linear-gradient(159deg, rgb(94, 127, 251) 5%, rgb(162, 197, 254) 48%, rgb(207, 254, 255) 95%)',
          }}
        >
          <Dropbox />
        </div>
      </Layout>
    );
  }
}
